import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import React, { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, NavLink, useNavigate } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import { setBreadcrumbItems } from "store/actions"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import Select from "react-select"
import { useFormik } from "formik"
import axios from "axios"
import _ from "lodash"
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage"
import { addDoc, collection, doc, Timestamp } from "firebase/firestore"
import { db, storage } from "config/firebase"
import Swal from "sweetalert2"
import * as Yup from "yup"

const AddProductSchema = Yup.object().shape({
  name: Yup.string().required("Nama Produk harus diisi"),
  price: Yup.string().required("Harga Produk harus diisi"),
  description: Yup.string().required("Deskripsi Produk harus diisi"),
  category: Yup.string().required("Kategori Produk harus diisi"),
})

const FormAddProduct = props => {
  document.title = "Form Add Product | CMS QR Pos"

  const breadcrumbItems = [
    { title: "Products", link: "/products" },
    { title: "Add Product", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Add Product", breadcrumbItems)
  })

  const [imageObj, setImageObj] = useState({})
  const [imageFile, setImageFile] = useState({})
  const [imageFileErrorMsg, setImageFileErrorMsg] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [categories, setCategories] = useState([])

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      name: "",
      price: "",
      description: "",
      category: "",
    },
    validationSchema: AddProductSchema,
    onSubmit: values => {
      handleAddNewProduct(values)
    },
  })

  const onDrop = useCallback(async acceptedFiles => {
    try {
      if (acceptedFiles.length > 0) {
        const formData = new FormData()
        const fileRaw = acceptedFiles[0]
        const previewUrl = URL.createObjectURL(fileRaw)

        formData.append("folder", "pos")
        formData.append("media", fileRaw)

        const { data } = await axios.post(
          `https://api-hp3ki.inovatiftujuh8.com/api/v1/media`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        )

        setImageObj(data.data)
        setImageFile({
          file: fileRaw,
          previewUrl,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }, [])

  const handleAddNewProduct = async values => {
    try {
      if (Object.keys(imageFile).length === 0) {
        setImageFileErrorMsg("Gambar Produk harus diisi")
      } else {
        setIsLoading(true)
        const { name, price, description, category } = values

        const payloads = {
          name,
          img: imageObj.path,
          price,
          desc: description,
          cat_id: category,
        }

        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL_API_QR_POS}/create-catalog-pos`,
          payloads,
        )

        setIsLoading(false)
        Swal.fire({
          icon: "success",
          title: "Produk baru berhasil ditambahkan",
          showConfirmButton: false,
          timer: 4000,
        })

        navigate("/products")
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        showConfirmButton: false,
        timer: 4000,
      })
      console.log(err)
    }
  }

  const getCategories = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_API_QR_POS}/category-pos`,
      )

      const formattedCategories = data.data.map(category => ({
        label: category.name,
        value: category.id,
      }))

      setCategories(formattedCategories)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getCategories()

    return () => {}
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">Form Add Product</CardTitle>
              <form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col md="12">
                    <Form className="mb-4">
                      <Label htmlFor="images">Images</Label>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          onDrop(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone dz-clickable">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="mdi mdi-cloud-upload-outline text-muted display-4"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {Object.keys(imageFile).length !== 0 && (
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={imageFile.file.name}
                                    src={imageFile.previewUrl}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {imageFile.file.name}
                                  </Link>
                                  {/* <p className="mb-0">
                                    <strong>{imageFile.formattedSize}</strong>
                                  </p> */}
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )}
                      </div>
                      {imageFileErrorMsg ? (
                        <small className="text-danger d-block mt-1">
                          {imageFileErrorMsg}
                        </small>
                      ) : null}
                    </Form>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="name">Name</Label>
                      <input
                        value={formik.values.name}
                        name="name"
                        id="name"
                        className="form-control"
                        type="text"
                        placeholder="Enter Name"
                        onChange={formik.handleChange}
                      />
                      {formik.errors.name && formik.touched.name ? (
                        <small className="text-danger d-block mt-2">
                          {formik.errors.name}
                        </small>
                      ) : null}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="price">Price</Label>
                      <input
                        value={formik.values.price}
                        name="price"
                        id="price"
                        className="form-control"
                        type="number"
                        placeholder="Enter Price"
                        onChange={formik.handleChange}
                      />
                      {formik.errors.price && formik.touched.price ? (
                        <small className="text-danger d-block mt-2">
                          {formik.errors.price}
                        </small>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="description">Description</Label>
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        onChange={e =>
                          formik.setFieldValue("description", e.target.value)
                        }
                        value={formik.values.description}
                        maxLength="225"
                        rows="3"
                        placeholder="Enter Product Description"
                      />
                      {formik.errors.description &&
                      formik.touched.description ? (
                        <small className="text-danger d-block mt-2">
                          {formik.errors.description}
                        </small>
                      ) : null}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="category">Category</Label>
                      <Select
                        onChange={selectedOption => {
                          formik.setFieldValue("category", selectedOption.value)
                        }}
                        name="category"
                        placeholder="Select Category"
                        options={categories}
                        classNamePrefix="select2-selection"
                      />
                      {formik.errors.category && formik.touched.category ? (
                        <small className="text-danger d-block mt-2">
                          {formik.errors.category}
                        </small>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <FormGroup className="mb-0 d-flex justify-content-end">
                  <div>
                    <NavLink to="/products">
                      <Button type="reset" color="secondary">
                        Cancel
                      </Button>
                    </NavLink>
                    <Button type="submit" color="primary" className="ms-1">
                      {!isLoading ? "Submit" : "Loading..."}
                    </Button>
                  </div>
                </FormGroup>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(FormAddProduct)
