import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form,
  Alert,
  Input,
  FormFeedback,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import PropTypes from "prop-types"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"

import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import { loginUser, socialLogin } from "../../store/actions"
import Swal from "sweetalert2"
// import megamenuImg from "../../assets/images/megamenu-img.png"
import logo from "../../assets/images/logo-bus-buddy.png"
// import logoLightPng from "../../assets/images/logo-.png"
import logoDark from "../../assets/images/logo-bus-buddy-dark.png"

const Login = props => {
  document.title = "Login | CMS Pos"

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const auth = getAuth()
  const navigate = useNavigate()

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Username harus diisi"),
      password: Yup.string().required("Password harus diisi"),
    }),
    onSubmit: values => {
      handleSignIn(values)
    },
  })

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
  }))

  const { error } = useSelector(LoginProperties)

  const signIn = type => {
    dispatch(socialLogin(type, props.router.navigate))
  }

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type)
  }

  const handleSignIn = async values => {
    try {
      setIsLoading(true)
      const { username, password } = values

      console.log(username, password)
      if (username === "Admin" && password === "password@2024") {
        localStorage.setItem("authUser", JSON.stringify(values))
        navigate("/")
      } else {
        Swal.fire({
          icon: "error",
          title: "Username atau password salah",
          text: "Silahkan coba lagi!",
          showConfirmButton: false,
          timer: 4000,
        })
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      const errorMessage = error.message
      Swal.fire({
        icon: "error",
        title: errorMessage,
        showConfirmButton: false,
        timer: 4000,
      })
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-2">
                    <Link to="/" className="d-block auth-logo">
                      <h3>CMS Pos</h3>
                    </Link>
                  </h3>

                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Welcome Back !
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue to CMS Pos.
                    </p>
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={validation.handleSubmit}
                    >
                      <div className="mb-3">
                        <Label htmlFor="username">Username</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter username"
                          type="username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username &&
                            validation.errors.username
                          }
                        />
                        {validation.touched.username &&
                        validation.errors.username ? (
                          <FormFeedback type="invalid">
                            {validation.errors.username}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="userpassword">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-2 mt-4">
                        <div className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-2 text-center">
                {/* <p>
                  Don't have an account ?{" "}
                  <Link to="/register" className="text-primary">
                    {" "}
                    Signup Now{" "}
                  </Link>
                </p> */}
                © {new Date().getFullYear()} CMS Pos{" "}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
