import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, NavLink } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Label, Modal, Row } from "reactstrap"
import { setBreadcrumbItems } from "store/actions"
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore"
import { db } from "config/firebase"
import {
  formatToDate,
  formatToDateString,
  formatToTime,
} from "helpers/date_helper"
import { convertNumberToCurrency } from "helpers/number_helper"
import Swal from "sweetalert2"
import moment from "moment"
import axios from "axios"
import Lightbox from "react-image-lightbox"
import Select from "react-select"

moment.locale()

const Products = props => {
  document.title = "Products | CMS Pos"

  const [isEffects, setIsEffects] = useState(false)
  const [productImage, setProductImage] = useState("")
  const [selectedCategory, setSelectedCategory] = useState({})
  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState({
    columns: [
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 250,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 200,
      },
      {
        label: "Price",
        field: "price",
        sort: "asc",
        width: 100,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: 100,
      },

      {
        label: "Actions",
        field: "actions",
        sort: "asc",
      },
    ],
  })
  const [modalDetailBus, setModalDetailBus] = useState(false)
  const authUser =
    localStorage.getItem("authUser") &&
    JSON.parse(localStorage.getItem("authUser"))

  const breadcrumbItems = [
    { title: "CMS Pos", link: "/dashboard" },
    { title: "Products", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Products", breadcrumbItems)
  })

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  // const handleShowDetailBus = async busId => {
  //   try {
  //     toggleModalDetailBus()
  //     const busesRef = doc(db, "buses", busId)
  //     const bus = await getDoc(busesRef)

  //     if (bus.exists()) {
  //       setBus({ ...bus.data(), id: bus.id })
  //     } else {
  //       console.log("No such document!")
  //     }
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  const getCategories = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_API_QR_POS}/category-pos`,
      )

      const formattedCategories = data.data.map(category => ({
        label: category.name,
        value: category.id,
      }))

      setSelectedCategory(formattedCategories[0])
      setCategories(formattedCategories)
    } catch (err) {
      console.log(err)
    }
  }

  const toggleModalDetailBus = () => {
    setModalDetailBus(!modalDetailBus)
    removeBodyCss()
  }

  const handleDeleteProduct = productId => {
    try {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      })

      Swal.fire({
        title: "Apakah sudah yakin?",
        text: "Anda tidak akan bisa mengembalikan data ini",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Iya, sudah yakin",
        cancelButtonText: "Tidak, belum yakin",
        reverseButtons: true,
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL_API_QR_POS}/delete-catalog-pos`,
            {
              id: productId,
            },
          )
          swalWithBootstrapButtons.fire({
            title: "Data dihapus!",
            text: "Data produk sudah berhasil dihapus",
            icon: "success",
          })

          window.location.reload()
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Dibatalkan",
            text: "Data produk kamu masih aman",
            icon: "error",
          })
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  console.log(selectedCategory.value)

  const getProductsByCategoryId = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL_API_QR_POS}/catalog-category-pos`,
        {
          cat_id: selectedCategory.value,
        },
      )

      const formattedRowProducts = data.data.catalogs.map(catalog => {
        return {
          image: (
            <img
              onClick={() => {
                setProductImage(catalog.img)
                setIsEffects(true)
              }}
              className="img-fluid "
              alt=""
              src={catalog.img}
              style={{
                cursor: "pointer",
                width: "5rem",
                height: "5rem",
                objectFit: "cover",
              }}
            />
          ),
          name: catalog.name,
          description: catalog.desc,
          price: `Rp ${convertNumberToCurrency(catalog.price)}`,
          category: data.data.category,
          actions: (
            <div className="d-flex gap-2 w-25">
              <NavLink to={`/products/${catalog.id}/edit`}>
                <button type="button" className="btn btn-warning">
                  <i className="ion ion-md-create"></i>
                </button>
              </NavLink>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => handleDeleteProduct(catalog.id)}
              >
                <i className="ion ion-md-trash"></i>
              </button>
            </div>
          ),
        }
      })

      setProducts(prevState => ({ ...prevState, rows: formattedRowProducts }))
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getCategories()

    return () => {}
  }, [])

  useEffect(() => {
    getProductsByCategoryId()
    return () => {}
  }, [selectedCategory])

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex flex-column row-gap-2 mb-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <CardTitle className="h4 mb-0">Products</CardTitle>
                  <NavLink to="/products/add">
                    <button type="submit" className="btn btn-primary">
                      <i className="ion ion-md-add-circle"></i> Add Product
                    </button>
                  </NavLink>
                </div>
                <div className="mb-1">
                  <Select
                    value={selectedCategory}
                    onChange={selectedOption => {
                      setSelectedCategory(selectedOption)
                    }}
                    placeholder="Filter By Category"
                    options={categories}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </div>

              <MDBDataTable
                hover
                noBottomColumns
                responsive
                striped
                data={products}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {isEffects && productImage ? (
        <Lightbox
          mainSrc={productImage}
          enableZoom={false}
          onCloseRequest={() => {
            setIsEffects(!isEffects)
          }}
        />
      ) : null}

      {/* <Modal
        size="lg"
        isOpen={modalDetailBus}
        toggle={() => {
          toggleModalDetailBus()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Detail Bus
          </h5>
          <button
            onClick={() => {
              setModalDetailBus(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="d-flex flex-column">
            <h6>{bus.name}</h6>
            <p>{bus.type}</p>
            <div className="d-flex flex-column column-gap-2 mb-4">
              <h6>Price</h6>
              <div>
                <p className="m-0">
                  Rp {convertNumberToCurrency(bus.price, "ID")}
                </p>
              </div>
            </div>
            <div className="d-flex flex-column column-gap-2 mb-4">
              <h6>Images</h6>
              <div className="popup-gallery d-flex column-gap-4 ">
                {bus.images?.map((image, idx) => {
                  return (
                    <Link to="#" className="float-start">
                      <div className="img-responsive">
                        <img
                          src={image.path}
                          onClick={() => {
                            setIsGallery(true)
                            setPhotoIndex(idx)
                            toggleModalDetailBus()
                          }}
                          alt=""
                          width="120"
                        />
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
            <div className="d-flex flex-column column-gap-2">
              <h6>Travel Time</h6>
              <div className="d-flex column-gap-2">
                <p className="m-0">
                  {bus.departureTime &&
                    `${formatToDateString(bus.departureTime, "dddd, DD MMMM")}, ${formatToTime(bus.departureTime)}`}
                </p>
                <p>-</p>
                <p className="m-0">
                  {bus.arrivalTime &&
                    `${formatToDateString(bus.arrivalTime, "dddd, DD MMMM")}, ${formatToTime(bus.arrivalTime)}`}
                </p>
              </div>
            </div>
            <div className="d-flex flex-column column-gap-2">
              <h6>Departure Location</h6>
              <div>
                <p className="m-0">{bus.departureLocation?.city}</p>
                <p className="m-0">{bus.departureLocation?.place}</p>
              </div>
            </div>
            <div className="d-flex flex-column column-gap-2 mt-3">
              <h6>Departure Location</h6>
              <div>
                <p className="m-0">{bus.destinationLocation?.city}</p>
                <p className="m-0">{bus.destinationLocation?.place}</p>
              </div>
            </div>
            <div className="d-flex flex-column column-gap-2 mt-3">
              <h6>Facilities</h6>
              <ul className="">
                {bus.facilities?.map(facility => {
                  return (
                    <li>
                      {facility.type === "seatAmount"
                        ? `Kapasitas ${facility.name} Kursi`
                        : facility.type === "seatSetting"
                          ? `Pengaturan Kursi ${facility.name}`
                          : facility.type === "ac"
                            ? `Full ${facility.name}`
                            : facility.name}
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="d-flex flex-column column-gap-2 mt-2 ">
              <h6>Seats</h6>
              <div>
                <p className="m-0">{`Column Seat (${bus.seatColumn})`}</p>
                <p className="m-0">{`Row Seat (${bus.seatRow})`}</p>
                <p className="m-0">{`Aisle Column (${bus.aisleColumn})`}</p>
                <p className="m-0">{`Remaining Seats (${bus.remainingSeats} Kursi)`}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {bus.images && isGallery && (
        <Lightbox
          mainSrc={bus.images[photoIndex].path}
          nextSrc={bus.images[(photoIndex + 1) % bus.images.length].path}
          prevSrc={
            bus.images[(photoIndex + bus.images.length - 1) % bus.images.length]
          }
          enableZoom={true}
          onCloseRequest={() => {
            setIsGallery(false)
          }}
          onMovePrevRequest={() => {
            setPhotoIndex(
              (photoIndex + bus.images.length - 1) % bus.images.length,
            )
          }}
          onMoveNextRequest={() => {
            setPhotoIndex((photoIndex + 1) % bus.images.length)
          }}
          imageCaption={bus.images[photoIndex].caption}
        />
      )} */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Products)
